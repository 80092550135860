import React, { useEffect, useRef, useState } from "react";
import enFlag from "../assets/images/en.jpg";
import frFlag from "../assets/images/fr.jpg";

const GoogleTranslate = () => {
    const [selectedLanguage, setSelectedLanguage] = useState("en");
    const [isOtherDivVisible, setIsOtherDivVisible] = useState(false);

    const observeLanguageChange = () => {
        const observer = new MutationObserver(() => {

            const translateDropdown = document.getElementById('google_translate_element');
            const selectedLang = translateDropdown.querySelector('.goog-te-combo').value;

            if (selectedLang === 'fr') {
                setIsOtherDivVisible(true);
            } else {
                setIsOtherDivVisible(false);
            }
        });

        const translateDropdown = document.getElementById('google_translate_element');
        observer.observe(translateDropdown, { childList: true, subtree: true });

        return () => {
            observer.disconnect();
        };
    };

    useEffect(() => {
        var addScript = document.createElement("script");
        addScript.setAttribute(
            "src",
            "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;

        // Start observing language change
        const cleanupObserver = observeLanguageChange();
        return () => {
            cleanupObserver();
        };
    }, []);

    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: "en",
                includedLanguages: "en,fr",
                autoDisplay: true,
                onLanguageChanged: function (lang) {
                    setSelectedLanguage(lang);
                }
            },
            "google_translate_element"
        );
    };

    return (
        <>
            <div className="d-flex flag-drop">
                {isOtherDivVisible ? (<img src={frFlag} alt="frFlag"/>) : (<img src={enFlag} alt="enFlag"/>)}<div id="google_translate_element"></div>
            </div>
        </>
    );
};

export default GoogleTranslate;
