import Image1 from "../../assets/images/login-reg-bg.png";
import {Link} from "react-router-dom";

const Login = () => {
  return (
    <section className="login-reg">
      <div className="overlay pt-120">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-6 order-xl-0 order-1">
              <div className="sec-img d-rtl">
                <img src={Image1} className="max-un" alt="log_image" />
              </div>
            </div>
            <div className="col-xl-5">
              <div className="section-text text-center">
                <h5 className="sub-title">Account</h5>
                <h2 className="title">Log in to Continue</h2>
              </div>
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="single-input">
                      <label htmlFor="logemail">Your Email</label>
                      <input
                        type="text"
                        id="logemail"
                        placeholder="Enter Your Email"
                      />
                    </div>
                    <div className="single-input mb-0">
                      <label htmlFor="logpassword">Your Password</label>
                      <input
                        type="text"
                        id="logpassword"
                        placeholder="Enter Your Password"
                      />
                    </div>
                    <Link to="#" className="float-end mb-3">Forgot Password</Link>
                    <button className="cmn-btn w-100">Login</button>
                  </div>
                </div>
              </form>
              <div className="forgot-pass mt-30 text-center">
                <p>Don’t have an account? <Link to="/sign-up">Sign up</Link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Login;
