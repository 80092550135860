const AboutBanner = () => {
  return (
    <section className="banner-section personal">
      <div className="overlay">
        <div className="banner-content">
          <div className="container wow fadeInUp">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-10">
                <div className="main-content text-center">
                  <div className="top-area section-text dark-sec">
                    <h5 className="sub-title">Personal</h5>
                    <h1 className="title">
                      Grow Your E-commerce Business with Azamra® Merchant Solutions.
                    </h1>
                    <p>Let your customers pay the way they want to pay: On the go, online or in person. Experience a wide range of payment solutions with total transparency. Fortress security with hidden no fees. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AboutBanner;
