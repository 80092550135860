import Image1 from "../../assets/images/icon/facebook.png";
import Image2 from "../../assets/images/icon/linkedin.png";
import Image3 from "../../assets/images/icon/instagram.png";
import logo from "../../assets/images/white-logo.png";
import {Link} from "react-router-dom";

const Footer = () => {

  // const scrollToSection = () => {
  //   const section = document.getElementById('sectionId');
  //   if (section) {
  //     section.scrollIntoView({ behavior: 'smooth' });
  //   } else {
  //
  //   }
  // };

  return (
    <footer className="footer-section pt-5">
      <div className="container">
        <div className="footer-area pt-5">
          <div className="footer-top">
            <div className="row">
              <div className="footer-logo col-sm-3 d-flex flex-column align-items-center justify-content-center">
                <Link className="mb-3"><img src={logo} alt="logo"/></Link>
                <div className="social-link d-flex align-items-center">
                  <Link to="#">
                    <img src={Image1} alt="icon" />
                  </Link>
                  <Link to="#">
                    <img src={Image2} alt="icon" />
                  </Link>
                  <Link to="#">
                    <img src={Image3} alt="icon" />
                  </Link>
                </div>
              </div>
              <div className="col-sm-3 d-flex justify-content-center justify-content-sm-start">
                <div className="menu-item dark-sec">
                  <h5 className="title">Useful links</h5>
                  <ul className="footer-link d-flex flex-column">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/business">Business</Link>
                    </li>
                    <li>
                      <Link to="/personal">Personal</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-3 d-flex justify-content-center justify-content-sm-start">
                <div className="menu-item dark-sec">
                  <h5 className="title">Useful links</h5>
                  <ul className="footer-link d-flex flex-column">
                    <li>
                      <Link to="/developers">Developers</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to={{ pathname: '/', search: '?help=true' }}>Help</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-3 d-flex justify-content-center justify-content-sm-start">
                <div className="menu-item dark-sec">
                  <h5 className="title">Contact Info</h5>
                  <ul className="footer-link contact-info d-flex flex-column">
                    <li>
                      <Link to="mailto:Info@Azamra.com"><i className="fa fa-envelope"></i>Info@Azamra.com</Link>
                    </li>
                    <li>
                      <Link to="tel:+12126344288"><i className="fa fa-phone"></i>+1 212 634 4288</Link>
                    </li>
                    <li>
                      <p><i className="fa fa-map-marker"></i><span>30 Wall Street, New York, NY 10005</span></p>
                    </li>
                    <li>
                      <p><i className="fa fa-map-marker"></i><span>Immeuble Hajal Warda, Yaounde, PO Box 16110 Yaounde</span></p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-6 col-sm-8 d-flex justify-content-center justify-content-sm-start order-sm-0 order-1">
                <div className="copyright text-center text-sm-start">
                  <p>
                    Copyrights © 2023 All Rights Reserved.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-8 d-flex justify-content-center justify-content-sm-end order-sm-0 order-1">
                <div className="copyright text-center text-sm-end">
                  <p>
                    Developed with ❤ by <Link to="https://www.softenica.com/" target="_blank">Softenica Technologies</Link>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
