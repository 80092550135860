import Image1 from "../../assets/images/seamless.jpg";
import Image2 from "../../assets/images/icon/global-payment-icon-1.svg";
import Image3 from "../../assets/images/icon/global-payment-icon-2.svg";
import Image4 from "../../assets/images/icon/global-payment-icon-3.svg";
import Image5 from "../../assets/images/icon/global-payment-icon-4.svg";

const GlobalPayment = () => {
  return (
    <section className="global-payment">
      <div className="overlay pt-120 pb-120">
        <div className="container wow fadeInUp">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-header text-center">
                <h2 className="title">Seamless Global Payments</h2>
                <p>
                  Pay Suppliers across the global with Azamra®  Global Payment Solutions. Many times faster and cheaper than your bank
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-6 col-xl-5 order-xl-0 order-1">
              <div className="image-area d-rtl left-side">
                <img src={Image1} alt="images" className="max-un" />
              </div>
            </div>
            <div className="col-xxl-6 col-xl-7">
              <div className="row cus-mar">
                <div className="col-sm-6 col-6 mb-3">
                  <div className="single-item">
                    <img src={Image2} alt="icon" />
                    <h5>Foreign Exchange (FX) Trading </h5>
                    <p>
                      Azamra provides a platform for foreign exchange trading, allowing users to buy and sell foreign currencies.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 col-6 mb-3">
                  <div className="single-item">
                    <img src={Image3} alt="icon" />
                    <h5>Online Money Transfer</h5>
                    <p>
                      Azamra offers a convenient online money transfer service that allows users to send and receive cross-border payments quickly and easily.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 col-6 mb-3">
                  <div className="single-item">
                    <img src={Image4} alt="icon" />
                    <h5>Currency Conversion</h5>
                    <p>
                      Azamra provides a currency conversion service that allows users to convert their money into the local currency of the country they are sending it to.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 col-6 mb-3">
                  <div className="single-item">
                    <img src={Image5} alt="icon" />
                    <h5>Payment Gateway</h5>
                    <p>
                      Azamra’s payment gateway allows users to make cross-border payments using their local currency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="global-payment-graph">
                <p>Africa and the Middle East are regions with great potential for economic growth. However, the lack of reliable cross-border payments infrastructure is often a barrier to doing business in these regions. </p>
                <p>The shortage of financial services in many of these countries means that cross-border payments are often slow and expensive. Businesses lose out on opportunities and enterprise-level organizations are unable to expand their cross-border operations. Individuals are also affected by the lack of access to banking services and reliable cross-border payment infrastructure.</p>
                <p>Azamra is committed to changing that by partnering with local technology and hardware providers to provide a platform for seamless cross-border payments and improving financial across Africa</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GlobalPayment;
