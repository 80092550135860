import Image1 from "../../../assets/images/strategies.jpg";

const AcceptPayments = () => {
  return (
    <section className="accept-payments">
      <div className="overlay pt-120 pb-120">
        <div className="container wow fadeInUp">
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-0 order-1">
              <div className="image-area d-rtl left-side">
                <img src={Image1} alt="images" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-header">
                <h2 className="title">Hedging strategies</h2>
                <p>
                  We help our clients hedge foreign exchange risks in a wide range of foreign currencies and implement customized strategies that help safeguard and strengthen their businesses.
                </p>
              </div>
              <div className="main-content">
                <div className="single-item">
                  <p>
                    We offer forward contracts, essentially “locking-in” foreign exchange rates for the exchange of currencies on a future date to protect profit margins.
                  </p>
                </div>
                <div className="single-item">
                  <p>
                    Our currency options and option strategies provide tailored customized currency hedging strategy that fits your risk mitigation needs. These option strategies can often be structured with little or no upfront payment.
                  </p>
                </div>
                <div className="single-item">
                  <p>
                    Cross-currency swaps – We help clients hedge long-dated foreign exchange risks by creating an appropriate asset-liability capital structure.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AcceptPayments;
