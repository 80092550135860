import {useEffect, useState} from "react";
import MainLogo from "../../assets/images/logo.jpg";
import "./header.scss";
import {Link, NavLink} from "react-router-dom";
import GoogleTranslate from "../GoogleTranslate";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = () => {
    const header = document.querySelector(".header-section");
    const scrollTop = window.scrollY;
    scrollTop >= 80
        ? header.classList.add("header-fixed")
        : header.classList.remove("header-fixed");
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header-section">
      <div className="overlay">
        <div className="container-fluid">
          <div className="row d-flex header-area">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                  <img src={MainLogo} className="logo" alt={MainLogo} />
                </Link>
                <button className="navbar-toggler" onClick={()=> toggleDropdown()} type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse justify-content-end ${isOpen ? 'show' : ""}`} id="navbarSupportedContent">
                  <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <NavLink to="/" className="nav-link">
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/about-us" className="nav-link">
                        About Us
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/business" className="nav-link">
                        Business
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/personal" className="nav-link">
                        Personal
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/developers">Developers</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/contact">Contact Us</NavLink>
                    </li>
                    <li className="nav-item">
                      <GoogleTranslate/>
                    </li>
                  </ul>
                  <div className="right-area header-action d-flex align-items-center max-un">
                    <Link to="/login" className="cmn-btn">
                      Login
                      <i className="icon-d-right-arrow-2"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
