import Image1 from "../../assets/images/app-download-img.jpg";
import Image2 from "../../assets/images/icon/check.png";
import Image3 from "../../assets/images/GooglePlay-btn.png";
import Image4 from "../../assets/images/apple-btn.png";
import {Link} from "react-router-dom";

export default function DownloadApp() {
    return(
        <>
            <section className="app-download">
                <div className="overlay pb-120">
                    <div className="container wow fadeInUp">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-lg-6 order-lg-0 order-1">
                                <div className="image-area d-rtl left-side">
                                    <img src={Image1} alt="images" className="max-un" />
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="section-area">
                                    <h5 className="sub-title">App Download</h5>
                                    <h2 className="title">Fast, Secure Money Transfers</h2>
                                    <p>
                                        Access your account via your mobile phone. View balance,
                                        transfer funds, view transactions wherever you are.
                                    </p>
                                </div>
                                <ul className="features">
                                    <li>
                                        <img src={Image2} alt="icon" />
                                        Login with fingerprint or Face ID.
                                    </li>
                                    <li>
                                        <img src={Image2} alt="icon" />
                                        Simple few Taps to send money
                                    </li>
                                    <li>
                                        <img src={Image2} alt="icon" />
                                        View transaction history.
                                    </li>
                                    <li>
                                        <img src={Image2} alt="icon" />
                                        Get instant App notifications.
                                    </li>
                                </ul>
                                <div className="brand-area mt-40">
                                    <Link to="#">
                                        <img src={Image3} alt="icon" />
                                    </Link>
                                    <Link to="#">
                                        <img src={Image4} alt="icon" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}