import bannerImage from "../../../assets/images/business-banner.jpg";

const Banner = () => {
  return (
    <section className="banner-section businessbanner">
      <div className="overlay">
        <div className="banner-content">
          <div className="container wow fadeInUp">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-6 col-md-7">
                <div className="main-content">
                  <div className="top-area section-text">
                    <h1 className="title mb-5">Currencies Risk Management</h1>
                    <h5 className="sub-title">Transact globally in multiple currencies and manage risk with our Azamra® Currency Solutions.</h5>
                    <p className="xlr">Currency fluctuations affect corporate profits. Azamra offers foreign exchange hedging products to minimize the risks associated with fluctuating currency markets and ultimately protect your bottom line.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-7">
                <img className="business-img" src={bannerImage} alt="img"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Banner;
