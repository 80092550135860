import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Banner from "../../components/Business/BusinessPayments/Banner";
import AcceptPayments from "../../components/Business/BusinessPayments/AcceptPayments";

const Business = () => {
  return (
    <>
      <Header />
        <section className="banner-section about-us business-wrap">
            <div className="overlay">
                <div className="banner-content">
                    <div className="container wow fadeInUp">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-10">
                                <div className="main-content text-center">
                                    <div className="top-area section-text dark-sec">
                                        <h1 className="title">
                                            Business
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Banner />
        <AcceptPayments />
      <Footer />
    </>
  );
};

export default Business;
