import Image1 from "../../assets/images/risk.jpg";
import {Link} from "react-router-dom";

const HomeBusiness = () => {
  return (
    <section className="app-download">
      <div className="overlay pb-120">
        <div className="container wow fadeInUp">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6">
              <div className="section-area">
                <h5 className="sub-title">Business</h5>
                <h2 className="title">Currencies Risk Management</h2>
                <strong className="mb-2 d-block">Transact globally in multiple currencies and manage risk with our Azamra® Currency Solutions.</strong>
                <p>
                  Currency fluctuations affect corporate profits. Azamra offers foreign exchange hedging products to minimize the risks associated with fluctuating currency markets and ultimately protect your bottom line.
                </p>
                <div className="bottom-area d-xxl-flex mt-4">
                  <Link to="/business" className="cmn-btn">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-5 order-lg-0 order-1">
              <div className="image-area d-rtl left-side">
                <img src={Image1} alt="images" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default HomeBusiness;
