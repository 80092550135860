import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import HomePage from "../pages/Home/Home";
import AboutUsPage from "../pages/About/About";
import LoginPage from "../pages/Login/Login";
import SignupPage from "../pages/Signup/Signup";
import Business from "../pages/Business/Business";
import Personal from "../pages/Personal/Personal";
import Company from "../pages/company/Company";
import Contact from "../pages/contact/Contact";

export const MainRouting = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route>
          <Route path="/" index element={<HomePage />}></Route>
          <Route path="/about-us" element={<AboutUsPage />}></Route>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/sign-up" element={<SignupPage />}></Route>
          <Route path="/business" element={<Business />}></Route>
          <Route path="/developers" element={<Company />}></Route>
          <Route path="/personal" element={<Personal />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
        </Route>
      </>
    )
  );
  return <RouterProvider router={router} />;
};
