import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import usaFlag from "../../assets/images/united-states-flag-icon.svg"
import cameroonFlag from "../../assets/images/cameroon-flag-icon.svg"
import {Link} from "react-router-dom";

export default function Contact() {
    return(
        <>
            <Header />
                <div className="contact-banner">
                    <h1 className="text-white">Contact Us</h1>
                </div>
                <div className="contact-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <form className="contact-get" action="">
                                    <h3>Get In Touch</h3>
                                    <ul>
                                        <li>
                                            <label htmlFor="">Full Name <small>*</small></label>
                                            <input type="text" className="form-control"/>
                                        </li>
                                        <li>
                                            <label htmlFor="">Email Address <small>*</small></label>
                                            <input type="email" className="form-control"/>
                                        </li>
                                        <li>
                                            <label htmlFor="">Subject <small>*</small></label>
                                            <input type="text" className="form-control"/>
                                        </li>
                                        <li>
                                            <label htmlFor="">Message</label>
                                            <textarea name="" className="form-control" id="" cols="30" rows="6"></textarea>
                                        </li>
                                        <li>
                                            <div className="bottom-area d-xxl-flex"><Link className="cmn-btn" href="#">Submit</Link></div>
                                        </li>
                                    </ul>
                                </form>
                            </div>
                            <div className="col-md-6">
                                <div className="contact-info">
                                    <h3>Contact Info</h3>
                                    <p>If you have any questions or would like to learn more about our innovative financial services visit us at one of our offices: </p>
                                    <ul>
                                        <li>
                                            <img src={usaFlag} alt="usaFlag"/>
                                            <span>United States <br/>30 Wall Street, New York, NY 10005</span>
                                        </li>
                                        <li>
                                            <img src={cameroonFlag} alt="cameroonFlag"/>
                                            <span>Cameroon <br/>Immeuble Hajal <br/> Warda, Yaounde, PO Box 16110 Yaounde</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-phone"></i>
                                            <Link to="tel:0012126344288">Tel: 001 212 634 4288</Link>
                                        </li>
                                        <li>
                                            <i className="fa fa-envelope"></i>
                                            <Link to="mailto:Info@Azamra.com">Email: Info@Azamra.com</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </>
    )
}