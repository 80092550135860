import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import AppDownload from "../../components/Home/AppDownload";
import Banner from "../../components/Home/Banner";
import FAQ from "../../components/Home/FAQ";
import GlobalPayment from "../../components/Home/GlobalPayment";
import HowItWorks from "../../components/Home/HowItWorks";
import OurSolutions from "../../components/Home/OurSolutions";
import HomeBusiness from "../../components/Home/HomeBusiness";
import Solutions from "../../components/Home/Solution";
import DownloadApp from "../../components/Home/DownloadApp";
import microsoftBadge from "../../assets/images/microsoft_badge.jpg"
import {Link, useLocation} from "react-router-dom";
import {useEffect} from "react";

const HomePage = () => {
    const location = useLocation();

    const scrollToSection = () => {
        const section = document.getElementById('sectionDown');
        const offset = -100; // Define a negative offset value here (in pixels)
        const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({ top: sectionPosition + offset, behavior: 'smooth' });
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get('help') === 'true') {
            yourFunction();
        }
    }, [location]);

    const yourFunction = () => {
        let scrollPosition;

        if (window.innerWidth > 768) {
            scrollPosition = 6871;
        } else {
            scrollPosition = 10462;
        }

        window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth'
        });
    };
  return (
    <>
      <Header />
      <Banner />
      <GlobalPayment />
      <OurSolutions />
      <AppDownload />
      <HomeBusiness/>
        <Solutions/>
        <HowItWorks/>
        <DownloadApp/>
        <FAQ/>
        <div className="video-section">
            <div className="d-flex align-items-center justify-content-between container">
                <video controls poster="">
                    <source src="https://frontend.devserver01.com/azamra-videos/microsoft-video.mp4" type="video/mp4" />
                </video>
                <Link target="_blank" to="https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://support.microsoft.com/en-au/office/extract-files-or-objects-from-a-powerpoint-file-85511e6f-9e76-41ad-8424-eab8a5bbc517%23:~:text%3DObjects%2520such%2520as%2520videos%252C%2520sound,and%2520paste%2520the%2520file%2520there.&ved=2ahUKEwil8d2W07KCAxXmgv0HHab7DdsQFnoECAgQBQ&usg=AOvVaw34GALcpL0atUlbBdSXcM7z"><img src={microsoftBadge} alt="microsoftBadge"/></Link>
            </div>
        </div>
      <Footer />
    </>
  );
};

export default HomePage;
