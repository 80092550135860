import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Image1 from "../../assets/images/companyImage.jpg";
import CompanyBanner from "./CompanyBanner";

const Company = () => {
  return (
    <>
      <Header />
      <CompanyBanner />
        <section className="app-download">
            <div className="overlay pt-120 pb-120">
                <div className="container wow fadeInUp">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-5 order-lg-0 order-1">
                            <div className="image-area width-area d-rtl left-side">
                                <img src={Image1} alt="images" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="section-area dev-content">
                                <h5 className="sub-title">Developers</h5>
                                <h2 className="title">Join our Community <br/>to Start Your Journey</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      <Footer />
    </>
  );
};

export default Company;
