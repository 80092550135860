import Banner from "../../components/AboutUs/Banner";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Image1 from "../../assets/images/about-img.jpg";
import whyImage from "../../assets/images/whyimage.jpg";
import Image6 from "../../assets/images/icon/innovation.jpg";
import Image7 from "../../assets/images/icon/transparency.jpg";
import Image8 from "../../assets/images/icon/accountability.jpg";
import Image9 from "../../assets/images/icon/leadership.jpg";
import {Link} from "react-router-dom";

const AboutUsPage = () => {
  return (
    <>
      <Header />
      <Banner />
        <section className="app-download">
            <div className="overlay pt-120 pb-120">
                <div className="container wow fadeInUp">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-5 order-lg-0 order-1">
                            <div className="image-area d-rtl left-side">
                                <img src={Image1} alt="images" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="section-area">
                                <h2 className="title">Our Story</h2>
                                <p className="mb-3">Azamra was founded in 2021 by a seasoned Wall Street banking executive who has leveraged her extensive banking experience and expertise to create a digital platform committed to improving financial inclusion and facilitating faster and more efficient financial transactions across Africa and the Middle East.</p>
                                <p className="mb-3">Cross-border payments in Africa and especially sub-sahara Africa are still largely reliant on slow and outdated banking methods which take days or even weeks to process. Azamra determined that this reality, coupled with the fact that a large percentage of the population in Africa and the Middle East are unbanked or underbanked, presented a unique opportunity in the fintech space.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="more-features">
            <div className="overlay pt-120 pb-120">
                <div className="container wow fadeInUp">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 text-center">
                            <div className="section-header dark-sec">
                                <h5 className="sub-title">Value</h5>
                                <h2 className="title">Our Core Values</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row cus-mar">
                        <div className="col-md-3 col-sm-6">
                            <div className="single-item">
                                <img src={Image6} alt="icon" />
                                <h5>Innovation</h5>
                                <p>We leverage technology to deliver superior financial services to our clients.</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="single-item">
                                <img src={Image7} alt="icon" />
                                <h5>Transparency</h5>
                                <p>Our clients’ best interests always come first.</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="single-item">
                                <img src={Image8} alt="icon" />
                                <h5>Accountability</h5>
                                <p>We deliver with pride and excellence.</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="single-item">
                                <img src={Image9} alt="icon" />
                                <h5>Leadership</h5>
                                <p>we challenge the status quo.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="app-download">
            <div className="overlay pt-120 pb-120">
                <div className="container wow fadeInUp">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-5 order-lg-0 order-1">
                            <div className="image-area d-rtl left-side">
                                <img src={whyImage} alt="images" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="section-area">
                                <h2 className="title">Why Azamra?</h2>
                                <p className="mb-3">Headquartered in New York City (USA), with the R&D team based in Israel, Azamra operates as a licensed digital banking platform in Central Africa with plans to expand across Africa. Transactions are completed via apps and website with a small number of branches and kiosks available primarily for cash transactions and the establishment of new accounts.</p>
                                <p className="mb-3">To achieve our goals, we have assembled a team of experts with a wealth of experience in the banking, financial services, and technology sectors. This includes our partnerships with some of the most innovative finance and tech companies in the world.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      <Footer />
    </>
  );
};

export default AboutUsPage;
