import Image1 from "../../assets/images/login-reg-bg.png";
import {Link} from "react-router-dom";

const Signup = () => {
  return (
    <section className="login-reg">
      <div className="overlay pt-120">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-6 order-xl-0 order-1">
              <div className="sec-img d-rtl">
                <img src={Image1} className="max-un" alt="log_Image" />
              </div>
            </div>
            <div className="col-xl-5">
              <div className="section-text text-center">
                <h5 className="sub-title">Get Started</h5>
                <h2 className="title">Welcome to Azamra</h2>
              </div>
              <form action="#">
                <div className="row">
                  <div className="col-12">
                    <div className="single-input">
                      <label htmlFor="logemail">Full Name</label>
                      <input
                        type="text"
                        id="logemail"
                        placeholder="Enter Full Name"
                      />
                    </div>
                    <div className="single-input">
                      <label htmlFor="logemail">Email Address</label>
                      <input
                        type="email"
                        id="logemail"
                        placeholder="Enter Email Address"
                      />
                    </div>
                    <div className="single-input">
                      <label htmlFor="logemail">Username</label>
                      <input
                        type="text"
                        id="logemail"
                        placeholder="Enter Username"
                      />
                    </div>
                    <div className="single-input">
                      <label htmlFor="logpassword">Password</label>
                      <input
                        type="text"
                        id="logpassword"
                        placeholder="Enter Password"
                      />
                    </div>
                    <div className="single-input">
                      <label htmlFor="logpassword">Confirm Password</label>
                      <input
                        type="text"
                        id="logpassword"
                        placeholder="Confirm Password"
                      />
                    </div>
                    <button className="cmn-btn w-100">Sign Up</button>
                  </div>
                </div>
              </form>
              <div className="forgot-pass mt-30 text-center">
                <p className="dont-acc">
                  Already have an account? <Link to="/login">Login</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Signup;
