import Image1 from "../../assets/images/icon/how-works-icon-1.png";
import Image2 from "../../assets/images/icon/how-works-icon-2.png";
import Image3 from "../../assets/images/icon/how-works-icon-3.png";
import Image4 from "../../assets/images/icon/how-works-icon-4.png";

const HowItWorks = () => {
  return (
    <section className="how-it-works">
      <div className="overlay pb-120">
        <div className="container wow fadeInUp">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-header text-center">
                <h5 className="sub-title">How it works?</h5>
                <h2 className="title">Just few steps to start</h2>
                <p>It's easier than you think. Follow 3 simple easy steps</p>
              </div>
            </div>
          </div>
          <div className="row cus-mar">
            <div className="col-xl-3 col-sm-6 col-6">
              <div className="single-item first text-center">
                <img src={Image1} alt="icon" />
                <h5>Register for free</h5>
                <p>Simply sign up online for free and verify your identity.</p>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-6">
              <div className="single-item second text-center">
                <img src={Image2} alt="icon" />
                <h5>Set up your transfer</h5>
                <p>Add a recipient's details and payment details.</p>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-6">
              <div className="single-item first text-center">
                <img src={Image3} alt="icon" />
                <h5>Make your payment</h5>
                <p>Fund your account and initiate your payment.
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-6">
              <div className="single-item text-center">
                <img src={Image4} alt="icon" />
                <h5>You're all done!</h5>
                <p>
                  We will inform you when the money has been sent.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default HowItWorks;
