import Image1 from "../../assets/images/about.jpg";
import {Link} from "react-router-dom";

const OurSolutions = () => {
  return (
    <section className="our-solutions" style={{background : 'rgb(36,91,229)'}}>
      <div className="overlay pt-120 pb-120">
        <div className="container wow fadeInUp">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-header dark-sec text-center">
                <h2 className="title">Why Azamra?</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-between mb-5 align-items-center">
            <div className="col-md-4">
              <div className="section-area dark-sec text-end">
                <h5 className="sub-title">Strategic Partnerships</h5>
                <p>
                  Azamra has partnered with a world leader in enterprise-level software for financial institutions, to develop our core banking software. We have also collaborated with leading technology and hardware providers in the region to solve connectivity issues and allow for seamless cash transactions.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="image-area about-image d-rtl left-side">
                <img src={Image1} alt="images"/>
              </div>
            </div>
            <div className="col-md-4">
              <div className="section-area dark-sec">
                <h5 className="sub-title">Mobile Transactions Drive Digital Banking Solutions</h5>
                <p>
                  With over 1.8 billion people in Africa and the Middle East, mobile phone penetration is high and growing. Consequently, the demand in these regions for mobile banking solutions that can provide access to comprehensive financial services is also on the rise.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="bottom-area dark-sec d-flex justify-content-center">
                <Link to="/about-us" className="cmn-btn">
                  About Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurSolutions;
