const FAQ = () => {
  return (
    <section className="faqs-section" id="sectionId">
      <div className="overlay pt-120 pb-120">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-7">
              <div className="section-header text-center">
                <h5 className="sub-title">Frequently Asked Questions</h5>
                <h2 className="title">If you got questions we have answer</h2>
                <p>We have a list of frequently asked questions about us</p>
              </div>
            </div>
          </div>
          <div className="row cus-mar">
            <div className="col-lg-6">
              <div className="accordion" id="accordionLeft">
                <div className="accordion-item">
                  <h6 className="accordion-header" id="headingLeftOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseLeftOne"
                      aria-expanded="false"
                      aria-controls="collapseLeftOne">
                      How to send money online?
                    </button>
                  </h6>
                  <div
                    id="collapseLeftOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingLeftOne"
                    data-bs-parent="#accordionLeft">
                    <div className="accordion-body">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipiscing elit
                        posuere vel venenatis, eu sit massa. Volutpat massa
                        rhoncus odio feugiat tellus elit massa sed ullamcorper a
                        in.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h6 className="accordion-header" id="headingLeftTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseLeftTwo"
                      aria-expanded="false"
                      aria-controls="collapseLeftTwo">
                      How much are money transfer fees?
                    </button>
                  </h6>
                  <div
                    id="collapseLeftTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingLeftTwo"
                    data-bs-parent="#accordionLeft">
                    <div className="accordion-body">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipiscing elit
                        posuere vel venenatis, eu sit massa. Volutpat massa
                        rhoncus odio feugiat tellus elit massa sed ullamcorper a
                        in.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h6 className="accordion-header" id="headingLeftThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseLeftThree"
                      aria-expanded="false"
                      aria-controls="collapseLeftThree">
                      What is the fastest way to send money abroad?
                    </button>
                  </h6>
                  <div
                    id="collapseLeftThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingLeftThree"
                    data-bs-parent="#accordionLeft">
                    <div className="accordion-body">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipiscing elit
                        posuere vel venenatis, eu sit massa. Volutpat massa
                        rhoncus odio feugiat tellus elit massa sed ullamcorper a
                        in.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h6 className="accordion-header" id="headingLeftFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseLeftFour"
                      aria-expanded="false"
                      aria-controls="collapseLeftFour">
                      How to use Azamra?
                    </button>
                  </h6>
                  <div
                    id="collapseLeftFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingLeftFour"
                    data-bs-parent="#accordionLeft">
                    <div className="accordion-body">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipiscing elit
                        posuere vel venenatis, eu sit massa. Volutpat massa
                        rhoncus odio feugiat tellus elit massa sed ullamcorper a
                        in.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h6 className="accordion-header" id="headingLeftFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseLeftFive"
                      aria-expanded="false"
                      aria-controls="collapseLeftFive">
                      How does Azamra protect your money?
                    </button>
                  </h6>
                  <div
                    id="collapseLeftFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingLeftFive"
                    data-bs-parent="#accordionLeft">
                    <div className="accordion-body">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipiscing elit
                        posuere vel venenatis, eu sit massa. Volutpat massa
                        rhoncus odio feugiat tellus elit massa sed ullamcorper a
                        in.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="accordion" id="accordionRight">
                <div className="accordion-item">
                  <h6 className="accordion-header" id="headingRightOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseRightOne"
                      aria-expanded="false"
                      aria-controls="collapseRightOne">
                      Are money transfer apps safe?
                    </button>
                  </h6>
                  <div
                    id="collapseRightOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingRightOne"
                    data-bs-parent="#accordionRight">
                    <div className="accordion-body">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipiscing elit
                        posuere vel venenatis, eu sit massa. Volutpat massa
                        rhoncus odio feugiat tellus elit massa sed ullamcorper a
                        in.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h6 className="accordion-header" id="headingRightTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseRightTwo"
                      aria-expanded="false"
                      aria-controls="collapseRightTwo">
                      How much money can I send?
                    </button>
                  </h6>
                  <div
                    id="collapseRightTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingRightTwo"
                    data-bs-parent="#accordionRight">
                    <div className="accordion-body">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipiscing elit
                        posuere vel venenatis, eu sit massa. Volutpat massa
                        rhoncus odio feugiat tellus elit massa sed ullamcorper a
                        in.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h6 className="accordion-header" id="headingRightThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseRightThree"
                      aria-expanded="false"
                      aria-controls="collapseRightThree">
                      Which currency can I send?
                    </button>
                  </h6>
                  <div
                    id="collapseRightThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingRightThree"
                    data-bs-parent="#accordionRight">
                    <div className="accordion-body">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipiscing elit
                        posuere vel venenatis, eu sit massa. Volutpat massa
                        rhoncus odio feugiat tellus elit massa sed ullamcorper a
                        in.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h6 className="accordion-header" id="headingRightFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseRightFour"
                      aria-expanded="false"
                      aria-controls="collapseRightFour">
                      Cancel transaction
                    </button>
                  </h6>
                  <div
                    id="collapseRightFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingRightFour"
                    data-bs-parent="#accordionRight">
                    <div className="accordion-body">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipiscing elit
                        posuere vel venenatis, eu sit massa. Volutpat massa
                        rhoncus odio feugiat tellus elit massa sed ullamcorper a
                        in.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h6 className="accordion-header" id="headingRightFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseRightFive"
                      aria-expanded="false"
                      aria-controls="collapseRightFive">
                      Can I send multiple payments?
                    </button>
                  </h6>
                  <div
                    id="collapseRightFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingRightFive"
                    data-bs-parent="#accordionRight">
                    <div className="accordion-body">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipiscing elit
                        posuere vel venenatis, eu sit massa. Volutpat massa
                        rhoncus odio feugiat tellus elit massa sed ullamcorper a
                        in.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
