import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Image1 from "../../assets/images/personalImage.jpg";
import iconslist from "../../assets/images/iconslist.jpg";
import AboutBanner from "./AboutBanner";

const Personal = () => {
  return (
    <>
      <Header />
      <AboutBanner />
        <section className="app-download">
            <div className="overlay pt-120 pb-120">
                <div className="container wow fadeInUp">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-5 order-lg-0 order-1">
                            <div className="image-area d-rtl left-side personal-mrgin">
                                <img src={Image1} alt="images" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="section-area personal-image">
                                <h2 className="title">270 + Connectors</h2>
                                <img src={iconslist} alt="iconslist"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      <Footer />
    </>
  );
};

export default Personal;
