import Header from "../../components/Header/Header";
import Signup from "../../components/Signup/Signup";

const SignupPage = () => {
  return (
    <>
      <Header />
      <Signup />
    </>
  );
};

export default SignupPage;
