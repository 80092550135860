import "../../customStyle.scss"
import logos from "../../assets/images/logos.png";
import cover1 from "../../assets/images/cover1.jpg";
import cover2 from "../../assets/images/cover2.jpg";
import cover3 from "../../assets/images/cover3.jpg";
import cover4 from "../../assets/images/cover4.jpg";
import cover5 from "../../assets/images/cover5.jpg";
import downArrow from "../../assets/images/down-arrow.svg";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Navigation } from 'swiper/modules';

const Banner = () => {

  const scrollToSection = () => {
    const section = document.getElementById('sectionDown');
    const offset = -100; // Define a negative offset value here (in pixels)
    const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: sectionPosition + offset, behavior: 'smooth' });
  };

  return (
      <>
        <div className="banner-wrapper-post">
          <Swiper
              navigation={true}
              modules={[Autoplay, Navigation]}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              className="mySwiper"
          >
            <SwiperSlide>
              <div className="banner-videos">
                <video autoPlay loop muted playsInline preload="auto" poster={cover1}>
                  <source src="https://frontend.devserver01.com/azamra-videos/media1.mp4" type="video/mp4" />
                </video>
                <h2>Tailored Cash Management Solutions</h2>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="banner-videos">
                <video autoPlay loop muted playsInline preload="auto" poster={cover2}>
                  <source src="https://frontend.devserver01.com/azamra-videos/media2.mp4" type="video/mp4" />
                </video>
                <h2>Treasury and Trade Solutions for African Businesses</h2>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="banner-videos">
                <video autoPlay loop muted playsInline preload="auto" poster={cover3}>
                  <source src="https://frontend.devserver01.com/azamra-videos/media3.mp4" type="video/mp4" />
                </video>
                <h2>Multi Currency Virtual Accounts</h2>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="banner-videos">
                <video autoPlay loop muted playsInline preload="auto" poster={cover4}>
                  <source src="https://frontend.devserver01.com/azamra-videos/media4.mp4" type="video/mp4" />
                </video>
                <h2>Seamless Global Payments</h2>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="banner-videos">
                <video autoPlay loop muted playsInline preload="auto" poster={cover5}>
                  <source src="https://frontend.devserver01.com/azamra-videos/media5.mp4" type="video/mp4" />
                </video>
                <h2>Digital Financial Solutions</h2>
              </div>
            </SwiperSlide>
          </Swiper>
          <img onClick={scrollToSection} src={downArrow} className="down_arrow" alt="down_arrow"/>
        </div>
        <div className="counter-section" id="sectionDown">
          <div className="container wow fadeInUp">
            <div className="row">
              <div className="col-md-12">
                <figure className="logos_wrapper">
                  <img src={logos} alt=""/>
                </figure>
              </div>
            </div>
            {/*<div className="row cus-mar align-items-center justify-content-between">*/}
            {/*  <div className="col-xl-3 col-md-3 col-sm-6">*/}
            {/*    <div className="single-area d-flex align-items-center justify-content-center">*/}
            {/*      <div className="text-area text-center">*/}
            {/*        <h2>*/}
            {/*          <span className="counter">270</span>*/}
            {/*          <span>+</span>*/}
            {/*        </h2>*/}
            {/*        <p>Integrations</p>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="col-xl-3 col-md-3 col-sm-6">*/}
            {/*    <div className="single-area d-flex align-items-center justify-content-center">*/}
            {/*      <div className="text-area text-center">*/}
            {/*        <h2>*/}
            {/*          <span className="counter">60</span>*/}
            {/*          <span>+</span>*/}
            {/*        </h2>*/}
            {/*        <p>Countries Covered</p>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="col-xl-3 col-md-3 col-sm-6">*/}
            {/*    <div className="single-area d-flex align-items-center justify-content-center">*/}
            {/*      <div className="text-area text-center">*/}
            {/*        <h2>*/}
            {/*          <span className="counter">30</span>*/}
            {/*          <span>+</span>*/}
            {/*        </h2>*/}
            {/*        <p>Currencies Supported</p>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </>
  );
};
export default Banner;
