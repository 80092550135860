const Banner = () => {
  return (
    <section className="banner-section about-us">
      <div className="overlay">
        <div className="banner-content">
          <div className="container wow fadeInUp">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-10">
                <div className="main-content text-center">
                  <div className="top-area section-text dark-sec">
                    <h1 className="title">
                      About Us
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Banner;
