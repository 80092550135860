import Image1 from "../../assets/images/icon/our-solutions-icon-1.png";
import Image2 from "../../assets/images/icon/our-solutions-icon-2.png";
import Image3 from "../../assets/images/icon/our-solutions-icon-3.png";
import Image4 from "../../assets/images/icon/our-solutions-icon-4.png";

const Solutions = () => {
    return (
        <section className="our-solutions half-overlay mb-80">
            <div className="overlay pt-120 pb-120">
                <div className="container wow fadeInUp">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-header dark-sec text-center">
                                <h5 className="sub-title">High speeds. Low fees. No hassle.</h5>
                                <h2 className="title">All Your Payments In One Place</h2>
                                <p>
                                    Get used to low fees and great exchange rates on international
                                    money transfers. Expand your business worldwide
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row cus-mar">
                        <div className="col-xl-3 col-6">
                            <div className="single-item">
                                <img src={Image1} alt="icon" />
                                <h5>Payments</h5>
                                <p>
                                    Make Global Payments with Azamra Wallets.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-6">
                            <div className="single-item">
                                <img src={Image2} alt="icon" />
                                <h5>Collections</h5>
                                <p>
                                    Seamlessly sell across borders and collect funds in local currencies.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-6">
                            <div className="single-item">
                                <img src={Image3} alt="icon" />
                                <h5>Conversions</h5>
                                <p>
                                    Conveniently convert local currencies to any digital assets.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-6">
                            <div className="single-item">
                                <img src={Image4} alt="icon" />
                                <h5>Global Account</h5>
                                <p>
                                    Manage your business with Azamra Multi-Currencies Virtual Accounts.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Solutions;