import Image1 from "../../assets/images/ecom.jpg";
import {Link} from "react-router-dom";

const AppDownload = () => {
  return (
    <section className="app-download">
      <div className="overlay pt-120 pb-120">
        <div className="container wow fadeInUp">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-5 order-lg-0 order-1">
              <div className="image-area d-rtl left-side">
                <img src={Image1} alt="images" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-area">
                <h5 className="sub-title">Personal</h5>
                <h2 className="title">Grow Your E-Commerce Business with Azamra® Merchant Solutions </h2>
                <p>
                  Let your customers pay the way they want to pay: On the go, online or in person. Experience a wide range of payment solutions with total transparency. Fortress security with no hidden fees.
                </p>
                <div className="bottom-area d-xxl-flex mt-4">
                  <Link to="/business" className="cmn-btn">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AppDownload;
